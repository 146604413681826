@import './variables';
@import 'buttons';

.payment-layout {
  background: $white;
  min-height: 100vh;

  .header {
    position: absolute;
    background: transparent;

    &__burger {
      color: $white !important;
      opacity: 0.8 !important;
    }

    &::before {
      content: none;
    }

    .nav,
    .theme-toggle {
      display: none;
    }

    .logo-desktop {
      color: $white;

      img {
        filter: $to-white;
      }
    }
  }
}

.payment {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  height: 100%;

  &__heading {
    padding: 300px 0 64px;
    position: relative;
    z-index: 1;

    .title {
      font-weight: 700;
      font-size: 28px;
      color: $white;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 8px;
      color: $gray-2;

      a {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .btn {
      display: inline-flex;
      background: $white;
      margin-top: 16px;

      img {
        margin-right: 10px;
      }
    }
  }

  &__bg {
    display: contents;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }

  &__footer {
    padding: 16px 0;
    color: $black-2;
    opacity: 0.65;
  }
}

@include breakpoint(lg) {
  .payment {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;

    &__heading {
      position: unset;
      padding: 246px 0 146px;
      max-width: 756px;

      .title {
        font-size: 72px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.44px;
        margin-bottom: 32px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }
    }

    .container {
      position: relative;
    }

    &__bg {
      img {
        z-index: unset;
      }
    }

    .btn {
      height: 62px;
    }

    &__footer {
      margin-top: auto;
      position: relative;
      background: transparent;
      opacity: 1;
      z-index: 1;

      p {
        background: $white;
        border-radius: 24px;
        padding: 24px;
      }
    }
  }
}
