// fonts
$font-fam-main: 'Roboto', 'Open Sans', sans-serif;

// colors
$black: #000000;
$black-2: #0b101c;
$black-3: #0f1523;

$gray: rgba(11, 16, 28, 0.06);
$gray-2: rgba(255, 255, 255, 0.7);
$gray-3: rgba(255, 255, 255, 0.12);
$gray-4: #dadee3;
$gray-5: rgba(11, 16, 28, 0.6);

$white: #ffffff;
$white-2: #fbfbfb;
$white-3: #eaeaea;

$white-gradient: linear-gradient(180deg, #ffffff, rgba(255, 255, 255, 0) 100%);
$box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);

$blue: #0069ff;
$blue-2: rgba(100, 160, 255);

//filter
$to-white: invert(97%) sepia(97%) saturate(13%) hue-rotate(250deg) brightness(105%) contrast(102%);
$to-blue: invert(36%) sepia(98%) saturate(5232%) hue-rotate(210deg) brightness(102%) contrast(108%);

$header-height-mob: 60px;
$header-height-desk: 84px;

$padding-mob: 16px;
$padding-desktop: 46px;

$btn-border-radius: 12px;
$btn-border-height: 2px;
