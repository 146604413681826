@import 'components/close-btn';
@import './variables';

.menu {
  position: fixed;
  inset: 0;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.3s,
    transform 0.2s;
  z-index: 6;
  background: $white-3;
  transform: translateY(+100%);

  &__inner {
    position: relative;
    border-radius: 24px 24px 0px 0px;
    margin-top: 62px;
    background: $white;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 32px;
      height: 3px;
      background: var(--color-border);
      border-radius: 32px;
    }
  }

  .is-menu-open & {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  &__logo {
    display: flex;
    justify-content: center;
    padding: 41px 0 17px;
    border-bottom: 1px solid var(--color-border);
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid var(--color-border);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);

    .close-btn {
      color: rgba(15, 21, 36, 0.5);
    }
  }

  .nav {
    img {
      display: none;
    }

    &__item {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.36px;
    }

    &__submenu {
      margin-top: -6px;
      padding-bottom: 18px;

      li {
        margin-top: 6px;
      }
    }

    a.active {
      color: unset;
    }
  }

  .theme-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dark & {
    background: $black-2;

    .menu {
      &__inner {
        background: linear-gradient(180deg, #1f242f 0%, #131723 100%);
      }

      &__close {
        box-shadow: none;

        .close-btn {
          color: $white;
        }
      }
    }
  }
}
