@import '../variables';
@import '../mixins';

.promo-bot {
  display: block;
  position: relative;
  padding: 76px 0 70px;
  margin-bottom: 24px;

  &__bg {
    display: contents;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 3;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 4;
    margin-bottom: 8px;
  }

  .title {
    color: $white;
    margin-bottom: 8px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 215px;
    justify-content: center;
    align-items: center;
    margin: -4px;
    opacity: 0.7;
    margin-bottom: 32px;
  }

  &__icon {
    margin: 4px;
    height: 28px;
    img {
      height: 100%;
      width: auto;
    }
  }

  .btn {
    background: $white;
    color: $black-2;
  }
}

@include breakpoint(lg) {
  .promo-bot {
    &__content {
      max-width: 900px;
      margin: 0 auto;
    }

    .title {
      margin-bottom: 20px;
    }

    &__list {
      max-width: unset;
      margin-bottom: 64px;
    }

    &__icon {
      height: 52px;
    }
  }
}
