@import './buttons';
@import './mixins';
@import './components/card';
@import './components/faq';

.home {
  text-align: center;

  &__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.home-heading {
  margin-bottom: 24px;

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
  }

  &__img {
    margin-bottom: -12px;
    height: auto;
  }

  &__link {
    display: inline-block;
    font-size: 28px;
    color: $blue;
    margin-bottom: 53px;
    font-weight: 900;
  }

  .btn {
    position: absolute;
    inset: 12px;
    top: unset;

    img {
      margin-right: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      filter: blur(12px);
      opacity: 0.65;
      background: $blue;
      inset: 0;
      z-index: -1;
    }
  }
}

.home-about {
  overflow: hidden;
  margin-bottom: 48px;

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $white;
    opacity: 0.24;
    margin: 24px 4px 0;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 38px 32px;
    height: 100%;
  }

  &__content {
    .title {
      margin-bottom: 12px;
    }

    p {
      color: var(--color-gray);
      font-weight: 500;
      line-height: 22px;
    }
  }

  &__pic {
    position: relative;
    display: block;
    height: 116px;
    width: 116px;

    img {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
      inset: 0;
    }
  }
}

.home-faq {
  margin-bottom: 48px;

  .home-faq__list {
    text-align: left;
    margin-bottom: 24px;
  }

  .title--xl {
    margin-bottom: 24px;
  }
}

@include breakpoint(lg) {
  .home {
    padding-bottom: 128px;
  }
  .home-heading {
    padding-top: 40px;
    .card {
      flex-direction: row;
      overflow: visible;
      padding: 0 40px 0 120px;
      text-align: left;
    }

    &__link {
      font-size: 36px;
      letter-spacing: -0.72px;
      margin-bottom: 44px;
    }

    &__img {
      margin-bottom: 0;
      margin-top: -40px;
      order: -1;
      width: 260px;
      margin-right: 96px;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    .btn {
      height: 66px;
      inset: unset;
      position: relative;
      width: fit-content;
    }
  }

  .home-about {
    &__item {
      flex-direction: row;
      text-align: left;
    }

    &__content {
      max-width: 65%;
      margin-right: 50px;
    }

    &__pic {
      width: 100%;
      height: 100%;
      max-width: 202px;
      margin-left: auto;
      order: 1;
    }

    &__content {
      .title {
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .home-faq {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;

    .title--xl,
    > .btn {
      margin-bottom: 64px;
    }

    > .btn {
      margin-left: auto;
    }

    &__list {
      order: 1;
    }
  }
}

@include breakpoint(xl) {
  .home-about {
    margin-bottom: 128px;
    .swiper {
      overflow: hidden;
      &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        transform: none !important;
      }

      &-slide {
        width: 100% !important;
      }

      &-pagination {
        display: none;
      }
    }
  }
}
