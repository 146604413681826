@import '../variables';

.toggle {
  cursor: pointer;

  &__label {
    display: inline-flex;
    align-items: center;
    background: $gray-4;
    border-radius: 100px;
    width: 38px;
    height: 24px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto 0;
      left: 4px;
      transition: all 0.25s;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &__input {
    position: absolute;
    visibility: hidden;

    &:checked + .toggle__label {
      background: $blue;

      &:before {
        left: 16px;
      }
    }
  }
}
