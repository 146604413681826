.footer {
  margin-top: auto;
  color: var(--color-gray);
  padding: $padding-mob 0 18px;
  border-top: 1px solid var(--color-border);

  &__logo {
    display: flex;
    justify-content: center;
    padding-bottom: $padding-mob;
    border-bottom: 1px solid var(--color-border);
  }

  &__copyright {
    border-top: 1px solid var(--color-border);
    padding-top: 18px;
    text-align: center;
  }

  .nav {
    padding: 0 $padding-mob;
    line-height: 24px;

    img {
      margin-left: auto;
    }

    &__title {
      padding: 12px 0;
    }

    &__submenu {
      margin-top: -4px;
      padding-bottom: 12px;

      li {
        margin-top: 8px;
      }
    }

    a.active {
      color: var(--color-foreground);
    }
  }
}

@include breakpoint(lg) {
  .footer {
    display: flex;
    align-items: flex-start;
    padding: 50px;

    &__logo,
    &__copyright {
      padding: 0;
      border: 0;
    }

    &__copyright {
      margin-left: auto;
      flex-shrink: 0;
    }

    .nav {
      margin-left: 128px;

      > ul {
        position: relative;
        padding-left: 220px;
        padding-bottom: 70px;
      }

      &__item {
        border: 0;

        img {
          display: none;
        }

        &.expand-content {
          position: absolute;
          left: 0;

          label {
            color: var(--color-foreground);
          }
        }

        &:nth-child(3) {
          top: 0;
        }

        &:nth-child(4) {
          top: 131px;
        }

        label {
          pointer-events: none;
          margin-bottom: 20px;
        }
      }

      &__title {
        padding: 0;
        margin-bottom: 8px;
      }

      &__submenu {
        height: auto;
        visibility: visible;
        display: block;
      }
    }
  }
}
