@import '../variables';

.close-btn {
  cursor: pointer;
  border: none;
  background: transparent;
  position: relative;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  transition: 0.15s ease;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    transform: rotate(-45deg);
  }

  &:before {
    transform: rotate(45deg);
  }
}
