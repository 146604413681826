@import './variables';
@import './mixins';
@import './components/card';

.contacts {
  &__list {
  }
}

.contacts-item {
  padding: 20px 64px 20px 20px;
  margin-bottom: 10px;
  position: relative;

  .title {
    margin-bottom: 8px;
  }

  &__icon {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@include breakpoint(md) {
  .contacts-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .title {
      margin-bottom: 0;
      margin-right: 40px;
    }

    p {
      margin-left: auto;
      width: 40%;
      max-width: 501px;
    }

    &__icon {
      width: 44px;
      height: 44px;
      position: relative;
      inset: unset;
      order: -1;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    &--phone {
      p {
        color: var(--color-foreground);
        font-size: 36px;
        font-weight: 900;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
    }

    &:nth-child(3) {
      p {
        color: var(--color-foreground);
        font-size: 36px;
        font-weight: 900;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
    }
  }
}
