@import './buttons';
@import './mixins';
@import './components/card';
@import 'variables';

.subscriptions {
  .title--xl {
    margin-bottom: 32px;
  }

  &__list {
    display: grid;
    gap: 16px 24px;
    margin-bottom: 16px;
  }

  &__description {
    display: flex;
    justify-content: center;
    font-weight: 500;
    text-align: center;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: var(--color-gray);

    p {
      max-width: 673px;
    }
  }
}

.subscriptions-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__heading {
    padding: 20px;
    border-bottom: 1px solid var(--color-border);
  }

  &__content {
    display: grid;
    padding: 20px;
    height: 100%;
  }

  &__price {
    strong {
      font-size: 36px;
      letter-spacing: -0.72px;
    }

    p {
      margin-top: 16px;
    }
  }

  &__period {
    position: absolute;
    border-radius: 200px;
    border: 1px solid $gray;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 8px 12px;
    color: var(--color-foreground);
    top: 20px;
    right: 20px;
    font-size: 12px;

    .dark & {
      border-color: rgba(255, 255, 255, 0.24);
      background: rgba(255, 255, 255, 0.08);
      box-shadow: none;
    }
  }

  &__description {
    margin-bottom: auto;

    li {
      position: relative;
      display: flex;
      align-items: flex-start;
      margin-bottom: 4px;
      padding-left: 26px;
      line-height: 24px;

      img {
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }

  .btn {
    margin-top: 36px;
  }
}

@include breakpoint(md) {
  .subscriptions {
    &__list {
      &--4 {
        justify-content: center;
        grid-template-columns: repeat(2, minmax(auto, 323px));
      }
    }
  }

  .subscriptions-item {
    &__period {
      font-size: 14px;
    }

    &__description {
      margin-bottom: 64px;
    }

    .btn {
      height: 43px;
    }
  }
}

@include breakpoint(lg) {
  .subscriptions {
    .title--xl {
      margin-bottom: 48px;
    }

    &__list {
      margin-bottom: 32px;

      &--3 {
        justify-content: center;
        grid-template-columns: repeat(3, minmax(auto, 323px));
      }
    }
  }
  .subscriptions-item {
    &__price {
      strong {
        font-size: 48px;
        letter-spacing: -0.96px;
      }
    }
  }
}

@include breakpoint(xl) {
  .subscriptions {
    &__list {
      &--4 {
        grid-template-columns: repeat(4, minmax(auto, 323px));
      }
    }
  }
}
