@import 'variables';
@import 'mixins';

.logo {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 200px;
  background: $blue;
  color: $white;
  margin: 0 auto;

  img {
    margin-right: 10px;
    filter: $to-white;
  }

  .dark & {
    background: $white;
    color: $black-2;

    img {
      filter: $to-blue;
    }
  }
}

.logo-desktop {
  display: none;
}

@include breakpoint(lg) {
  .logo {
    display: none;
  }

  .logo-desktop {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    font-weight: 900;
    font-size: 20px;
    letter-spacing: -0.4px;
    color: var(--color-foreground);

    img {
      margin-right: 26px;
      filter: $to-blue;
    }

    .dark & {
      img {
        filter: $to-white;
      }
    }
  }
}
