@import '../variables';

.card {
  border-radius: 16px;
  background: $white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;

  p {
    font-weight: 500;
    line-height: 22px;
    color: var(--color-gray);
  }

  .dark & {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 159.61%
    );
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: none;
  }

  &--gradient-sm {
    .dark & {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
