@import 'variables';
@import 'mixins';
@import './components/card';
@import './buttons';

.bots {
  text-align: center;
  padding-top: 0;

  &__pic-wrap {
    padding-top: 70px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 81px;
      background: linear-gradient(0deg, #fbfbfb 0%, rgba(251, 251, 251, 0) 100%);
      bottom: 0;
    }
  }

  &__pic {
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      width: 245px;
      height: 379px;
      background: $blue;
      opacity: 0.85;
      filter: blur(74px);
      border-radius: 50%;
      z-index: -1;
      top: 46px;
    }
  }

  .title--xl {
    margin-bottom: 16px;
  }

  &__descripton {
    color: var(--color-gray);
  }

  .btn {
    font-weight: 500;

    img {
      margin-right: 10px;
    }
  }

  .dark & {
    .bots {
      &__pic-wrap {
        &:after {
          background: linear-gradient(0deg, #0b101c 0%, rgba(11, 16, 28, 0) 100%);
        }
      }
    }
  }
}

.bots-gallery {
  margin: 28px 0;

  .swiper {
    max-width: fit-content;
    overflow: visible;
  }

  .swiper-slide {
    width: fit-content;
  }

  .container {
    overflow: hidden;
  }

  &__item {
    padding: 20px;
    display: flex;
    align-items: center;
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 20px;
    filter: $to-blue;
  }

  &__title {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
  }

  .dark & {
    .bots-gallery__icon {
      filter: $to-white;
    }
  }
}

@include breakpoint(md) {
  .bots {
    &__pic {
      &::before {
        top: 77px;
        width: 335px;
        height: 189px;
      }
      img {
        width: 276px;
        height: auto;
      }
    }

    &__pic-wrap {
      margin-bottom: 48px;
    }
  }

  .bots-gallery {
    margin: 48px 0;

    &__item {
      padding: 24px;
      display: block;
      text-align: left;
    }

    &__icon {
      margin-bottom: 24px;
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
