@import '../variables';

.faq-item {
  position: relative;
  margin-bottom: 10px;
  padding: 16px 20px;
  text-align: left;

  input:checked + label {
    margin-bottom: 10px;
  }

  .expand-content__inner {
    padding-right: 40px;
    color: var(--color-gray);

    a {
      color: $blue;
    }
  }

  .btn {
    padding: 0;
    width: 36px;
    height: 36px;
    margin-left: auto;
    flex-shrink: 0;

    img {
      filter: $to-white;
    }
  }
}

@include breakpoint(lg) {
  .faq-item {
    padding: 32px 36px;

    input:checked + label {
      margin-bottom: 20px;
    }

    .expand-content__inner {
      font-size: 18px;
      padding-right: 48px;
    }

    .btn {
      width: 44px;
      height: 44px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
