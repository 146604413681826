.header {
  position: sticky;
  top: 0;
  height: $header-height-mob;
  width: 100%;
  border-bottom: 1px solid var(--color-border);
  z-index: 5;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    transition: 0.3s;
    z-index: -1;
  }

  &.scrolled {
    background: var(--color-background);

    &::before {
      opacity: 1;
    }
  }

  .container {
    height: 100%;
  }

  &__wrap {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 40px 0 $padding-mob;
  }

  &__burger {
    border: none;
    background: none;
    color: $black-2;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.45;
    cursor: pointer;

    i {
      &::before,
      &::after,
      span {
        content: '';
        border-radius: 2px;
        display: block;
        height: 2px;
        margin: 3px 0;
        transform-origin: center;
        transition: transform 0.15s ease;
        width: 22px;
        background: currentColor;
      }
    }
  }

  .nav,
  .theme-toggle {
    display: none;
  }

  .dark & {
    &::before {
      content: none;
    }

    .header__burger {
      border-color: $white;
      opacity: 1;
      color: $white;
    }
  }
}

@include breakpoint(lg) {
  .header {
    &__wrap {
      padding: 0 $padding-desktop;
    }

    &__burger {
      display: none;
    }

    .nav {
      display: block;
      margin: 0 auto;
      width: unset;
      color: var(--color-gray);

      > ul {
        display: flex;
        flex-direction: row;
      }

      a {
        img {
          display: none;
        }
      }

      &__item {
        border: 0;
        padding: 0 18px;
        input {
          display: none;
        }

        &:hover {
          .nav__submenu {
            height: auto;
            visibility: visible;
          }
        }

        label {
          pointer-events: none;
        }

        .nav__title {
          img {
            opacity: 0.7;
            margin-left: 12px;
          }
        }
      }

      &__submenu {
        position: absolute;
        background: var(--color-background);
        border: 1px solid rgba(255, 255, 255, 0.24);
        filter: drop-shadow(0px 16px 64px rgba(0, 0, 0, 0.25));
        border-radius: 16px;
        padding: 8px;
        width: unset;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 15px;

        li {
          a {
            display: inline-block;
            padding: 10px 12px;
            border-radius: 10px;

            &.active {
              background: rgba(255, 255, 255, 0.08);
            }
          }
        }
      }
    }

    .theme-toggle {
      display: flex;
      align-items: center;

      .toggle {
        margin-left: 12px;
      }
    }

    .logo {
      margin: 0;
    }
  }
}
