@import './variables';

.nav {
  display: flex;
  width: 100%;

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  &__item {
    position: relative;
    border-top: 1px solid var(--color-border);

    img {
      opacity: 0.45;
    }

    &:has(.active) {
      .nav__title {
        color: var(--color-foreground);

        img {
          opacity: 1;
        }
      }
    }

    &:first-child {
      border-top: 0;
    }
  }

  &__title,
  .theme-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 18px 0;
  }

  &__submenu {
    color: var(--color-gray);
    letter-spacing: -0.3px;
    font-weight: 500;

    li {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  a {
    img {
      transform: rotate(-90deg);
    }
  }

  a.active {
    color: var(--color-foreground);
    pointer-events: none;
  }

  .dark & {
    img {
      opacity: 1;
      filter: $to-white;
    }
  }
}
