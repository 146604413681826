.expand-content {
  margin-bottom: 0;

  input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;

    &:checked + label {
      img {
        transform: rotate(180deg);
      }
      & + .expand-content__inner {
        height: auto;
        visibility: visible;
      }
    }

    &:not(:checked) + label {
      padding-bottom: 0;
    }
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      transition: transform 0.3s;
    }
  }

  &__inner {
    visibility: hidden;
    height: 0px;
    overflow: hidden;
  }
}
