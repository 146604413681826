@import './variables';
@import './mixins';

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient($blue-2, $blue);
  padding: 0 32px;
  height: 40px;
  color: $white;
  border-radius: $btn-border-radius;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    background-color: $blue;
    border-radius: calc($btn-border-radius - $btn-border-height);
    position: absolute;
    inset: 2px;
    z-index: -1;
    transition: inherit;
  }

  &--lg {
    height: 54px;
    font-weight: 700;
  }

  &--no-border {
    background: unset;
    color: $black-2;

    &::before {
      content: none;
    }
  }

  &:hover {
    &::before {
      opacity: 0.7;
    }
  }
}

@include breakpoint(md) {
  .btn {
    font-size: 16px;

    &--lg {
      height: 60px;
    }
  }
}
